<template>
  <q-popup-edit
    v-model="popup"
    ref="categorySearchPopup"
    :cover="false"
    :offset="[0, 10]"
  >
    <div class="h-search-category">
      <div class="row">
        <div class="col">
          <q-list
            dense
            v-for="(cat, index) in allCategories"
            :key="cat.categoryID"
          >
            <q-item
              clickable
              v-if="index % 2 == 0"
              :active="searchCategory.categoryName == cat.categoryName"
              active-class="text-blue"
            >
              <q-item-section @click="setSearchCategory(index)">
                {{ $options.filters.titleCase(cat.categoryName) }}
              </q-item-section>
            </q-item>
          </q-list>
        </div>
        <div class="col">
          <q-list
            dense
            v-for="(cat, index) in allCategories"
            :key="cat.categoryID"
          >
            <q-item
              clickable
              v-if="index % 2 != 0"
              :active="searchCategory.categoryName == cat.categoryName"
              active-class="text-blue"
            >
              <q-item-section @click="setSearchCategory(index)">{{
                $options.filters.titleCase(cat.categoryName)
              }}</q-item-section>
            </q-item>
          </q-list>
        </div>
        <!-- <div class="col">
          <q-list
            dense
            v-for="(cat, index) in allCategories"
            :key="cat.categoryID"
          >
            <q-item
              clickable
              v-if="index % 3 == 2"
              :active="searchCategory.categoryName == cat.categoryName"
              active-class="text-blue"
            >
              <q-item-section @click="setSearchCategory(index)">{{
                cat.categoryName
              }}</q-item-section>
            </q-item>
          </q-list>
        </div> -->
        <!-- ToDo: v-if Temporary -->
        <div class="col-4 searchCategory" v-if="false">
          <q-img src="images/search/searchCategoryImage.jpg"></q-img>
          <h6>No Contact Service</h6>
          <p>We understand the seriousness</p>
        </div>
      </div>
    </div>
  </q-popup-edit>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      popup: false,
      active: true,
    }
  },
  computed: {
    allCategories() {
      let categories = [
        { categoryID: '', categoryName: 'All' },
        ...this.categories,
      ]
      return categories
    },
    ...mapGetters('category', ['searchCategory', 'categories']),
  },
  methods: {
    setSearchCategory(catIndex) {
      this.$store.commit(
        'category/SET_SELECTED_SEARCH_CATEGORY',
        this.allCategories[catIndex]
      )
      this.$refs.categorySearchPopup.hide()
      this.changeDialogState({
        dialog: 'selectCategory',
        val: false,
      })
    },
  },
}
</script>
<style lang="scss">
.h-search-category {
  width: 800px;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 600;
  .q-item {
    padding: 0 5px;
    color: #0f141a;
    .q-focus-helper {
      opacity: 0 !important;
    }
    &.text-blue,
    &:hover {
      color: $primaryOnBody !important;
      color: var(--q-color-primaryOnBody) !important;
      text-decoration: underline;
    }
  }
  a {
    font-size: 14px;
    color: #0f141a;
  }
  .searchCategory {
    h6 {
      margin: 15px 0 0;
      padding: 0;
      color: $primary;
      color: var(--q-color-primary) !important;
      font-size: 16px;
      font-family: 'Poppins', sans-serif;
      @media (min-width: 1681px) {
        margin-bottom: 5px;
        font-size: 20px;
      }
    }
    p {
      font-size: 14px;
      color: #888888;
    }
  }
  @media (max-width: 1023px) {
    width: 100%;
    padding: 10px;
  }
}
</style>
