import { render, staticRenderFns } from "./CategorySelectPopup.vue?vue&type=template&id=83eb1d40"
import script from "./CategorySelectPopup.vue?vue&type=script&lang=js"
export * from "./CategorySelectPopup.vue?vue&type=script&lang=js"
import style0 from "./CategorySelectPopup.vue?vue&type=style&index=0&id=83eb1d40&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPopupEdit from 'quasar/src/components/popup-edit/QPopupEdit.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QColor from 'quasar/src/components/color/QColor.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPopupEdit,QList,QItem,QItemSection,QImg,QColor});
